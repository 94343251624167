@media only screen and (max-height:701px){
    h2{
      font-size:2.75rem;
    }
h2.css-i1hrn3-MuiTypography-root{
            font-size:2.75rem
    }
    h5.css-41an0y-MuiTypography-root{
        font-size:1.3rem;
    }
h3.css-1qnne57-MuiTypography-root{
font-size:2rem;
}
    h3{
        font-size:3rem;
    }
    h4{
        font-size:1.23rem
    }
    h4.css-5ukp3i-MuiTypography-root{
        font-size:1.23rem
    }
  }